.button {
    cursor: pointer;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    text-align: center;
    min-width: 160px;
    padding: 0 15px;
    text-transform: uppercase;
    outline: none;
    color: $blue;
    border: 2px solid $blue;
    transition: all 0.3s;
    &:not(:disabled):hover {
        box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.25);
    }
    
    &--link {
        border: none;
    }
    &--primary {
        @include gradientBG();
        font-weight: 600;
        color: #fff;
        border: none;
    }

    &[disabled] {
        opacity: 0.6;
    }
}