.react-hint {
    background: $darkGray;
    color: white;
    padding: 10px;
    white-space: pre-line;
    line-height: 20px;
    text-align: center;
    &:after {
        bottom: -5px;
        border-top-color: $darkGray;
    }
    &__hint {
        display: inline-flex;
        flex-direction: column;
        font-size: 12px;
        &-input {
            background: $darkGray;
            border: 1px solid $deepGray;
            margin-bottom: 5px;
            direction: ltr;

            &::selection {
                background: $primary-color;
            }
        }
    }
}