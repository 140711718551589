.lang-wrapper {
    .lang-btn {
        width: 35px;
        height: 35px;
        background: none center center/contain no-repeat;
        border: 1px solid $separator;
        &:not(:first-of-type) {
            border-right: 0;
        }
        &.en {
            background-image: url(/images/flags/en.png);
        }
        &.he {
            background-image: url(/images/flags/he.png);
        }
        &.active {
            background-color: $primary-color;
        }
    }
}