.text-html {
    line-height: 30px;
    font-size: 18px;

    @include mobileOnly() {
        font-size: 16px;
        line-height: 25px;
    }

    strong {
        font-weight: bold;
    }
    p {
        padding-bottom: 10px;
    }
    a {
        color: $blue;
        text-decoration: underline;
    }
    ul {
        list-style-type: disc;
    }
    ol {
        list-style-type: decimal;
    }
    ul, ol {
        display: block;
        -webkit-margin-before: 1em;
        -webkit-margin-after: 1em;
        -webkit-margin-start: 0px;
        -webkit-margin-end: 0px;
        -webkit-padding-start: 40px;
    }
    li {
        display: list-item;
        text-align: -webkit-match-parent;
    }
    h3, h3 * {
        color: $blue !important;
        font-weight: 500;
    }
}