.crop-modal {
    max-width: unset;

    &__content {
        padding: 20px;
    }

    &__explain {
        margin-bottom: 10px;
    }

    &__preview {
        overflow: hidden;
        height: 200px;
        img {
            width: 100% !important;
            height: 100% !important;
            object-fit: cover;
        }
        &.web {
            width: 532px;
        }
        &.mobile {
            width: 300px;
        }
    }
    &__previewTitle {
        font-size: 18px;
    }
    &__previewWrapper {
        margin-top: 20px;
    }
    &__previewItem {
        margin: 0 10px;
    }
    &__not-supported {
        min-width: 500px;
        display: inline-block;
        @include mobileOnly {
            min-width: 250px;
        }
    }

    &__previews {
        margin-top: 20px;
        .previews-container {
            display: flex;
            flex-direction: row;
            margin-top: 10px;
        }
        .preview {
            margin: 0 5px;
            &__img {
                border: 1px solid $primary-color;
                background: none center center/cover no-repeat;
            }
        }
    }
}