.shareable-links {
    .shareable {
        &-wrapper {
            margin: 5px 15px;
            position: relative;
        }
        cursor: pointer;

        &:hover {
            opacity: 0.8;
        }
        &__icon {
            @include square(50px);
            background: $blue;
            border-radius: 50%;
            position: relative;
            margin-bottom: 2px;
            &:after {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background-repeat: no-repeat;
                background-position: center center;
                display: inline-block;
                content: ' ';
            }
        }
        &__text {
            color: $blue;
            text-align: center;
            font-size: 12px;
            line-height: normal;
        }
        &.fb .shareable__icon:after {
            background-image: url(/images/link-fb.png)
        }
        &.ln .shareable__icon:after {
            background-image: url(/images/link-in.png)
        }
        &.wa .shareable__icon:after {
            background-image: url(/images/link-wa.png)
        }
        &.url .shareable__icon:after {
            background-image: url(/images/link-url.png)
        }
    }

    .react-hint {
        left: 9px;
        top: 80px;
    }
    .shareable__hidden {
        position: absolute;
        width: 19px;
        height: 19px;
        opacity: 0.3;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: white;
        background: white;
    }
}