.cover-image {
    @include publicWidth();
    @include size(auto, 100%);
    overflow: hidden;
    position: relative;
    margin: auto;
    @include mobileOnly {
        @include size(auto, 100%);
    }
    &__img-wrapper {
        @include square(100%);
        &.compat-mode {
            height: 450px;
            background-size: cover;
            background-position: center;
        }
    }
    &__img {
        @include square(100%);
        object-fit: cover;
        max-height: 500px;
    }

    &__incentive {
        @include publicWidth();
        background: $background;
        color: $blue;
        font-weight: 700;
        font-size: 36px;
        padding: 20px 5px 0;
        position: relative;
        bottom: 0;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        
        @include mobileOnly {
            width: calc(100% - #{$mainSpacing});
            font-size: 25px;
            top: -20px;
        }
    }
}