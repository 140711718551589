.applicant-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    @include flex();
    position: relative;

    &__cvFile {
        text-decoration: underline;
        color: $blue;
        &:hover {
            color: rgba($blue, 0.8);
        }
    }
    .ReactTable {
        margin-top: 7px;
        .rt-tbody .rt-tr {
            height: 100%;
            padding: 5px 0;
        }
        .title-column-header {
            justify-content: flex-start;
            padding: 0 25px;
        }
    }
    .title-column {
        text-align: left;
        @include rtl {
            text-align: right;
        }
        padding: 5px 20px;
        &__name {
            font-weight: bold;
            margin: 5px 0;
            &:hover {
                color: $blue;
            }
        }
        &__job {
            color: $deepGray;
            margin-top: 2px;
            &:hover {
                color: $blue;
            }
        }
        .icon {
            color: $blue;
            margin-left: 4px;
        }
        .job-highlight {
            margin: 5px 0 0 15px;
        }
    }
    .referrer-column {
        padding: 5px 20px;
        &__name {
            margin: 5px 0;
        }
    }
    &__export {
        align-self: flex-end;
    }
}