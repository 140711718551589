
.menu {
    @include size(100%, $adminMenuWidth);
    transition: .25s ease-in-out;
    box-shadow: -1px 0px 13px #ababab;
    z-index: 1000;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow-y: auto;

    &__list {
        padding: 0;
    }
    .menu-item {
        overflow: hidden;
        
        &__content {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 60px;
            padding: 0 20px;
            overflow: hidden;
            position: relative;
            color: white;
            cursor: pointer;
            
            &.active {
                color: white;
                font-weight: bold;
                pointer-events: none;
                cursor: initial;
                &:before{
                    content: "";
                    background-color: $blue;
                    width: 5px;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    @include rtl {
                        left: unset;
                        right: 0;
                    }
                }
            }
            &:hover {
                color: $blue;
            }
        }
        &__inner {
            ul {padding: 0};

            max-height: 0;
            transition: max-height 0.5s;

            .menu-item__content{
                padding: 0 40px 0 57px;
                position: relative;
                height: 42px;
                @include rtl {
                    padding: 0 57px 0 40px;
                }
            }
        }

        &.open {
            background-color: $darkerGray;
            .menu-item__inner {
                max-height: 300px;
                padding-bottom: 10px;
            }
        }

        &:hover {
            background-color: $darkerGray;
        }

        .menu-item__icon {
            width: 30px;
            background-position: center;
            background-repeat: no-repeat;
        }
    }
}