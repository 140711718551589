$inputWidth: 260px;
$adminHeaderHeight: 80px;
$adminMenuWidth: 230px;
$maxScreenSize: 1800px;
$maxMainSize: $maxScreenSize - $adminMenuWidth;
$mapHeight: 260px;
$maxPublicSize: 1200px;
$mainSpacing: 20px;
$footerHeight: 118px;
$publicHeaderHeight: 36px;
