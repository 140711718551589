@import 'components/Flex/Flex.scss';
@import 'components/Form/Form.scss';
@import 'components/Button/Button.scss';
@import 'components/ElevatedBox/elevatedBox.scss';
@import 'components/Title/title.scss';
@import 'components/Main/main.scss';
@import 'components/ErrorMessage/errorMessage.scss';
@import 'components/CoverImage/CoverImage.scss';
@import 'components/ClickToCopy/clickToCopy.scss';
@import 'components/Menu/menu.scss';
@import 'components/Modal/modal.scss';
@import 'components/ConfirmationModal/confirmationModal.scss';
@import 'components/ApplicantList/ApplicantList.scss';
@import 'components/ApplicantsFilter/ApplicantsFilter.scss';
@import 'components/CreateEditCompany/CreateEditCompany.scss';
@import 'components/CreateEditJob/CreateEditJob.scss';
@import 'components/CreateEditSettings/createEditSettings.scss';
@import 'components/CreateEditReferrerModal/createEditReferrerModal.scss';
@import 'components/JobHighlight/JobHighlight.scss';
@import 'components/JobItem/JobItem.scss';
@import 'components/Jobs/Jobs.scss';
@import 'components/JobsList/jobsList.scss';
@import 'components/TextHtml/textHtml.scss';
@import 'components/Loader/loader.scss';
@import 'components/LanguagePicker/languagePicker.scss';
@import 'components/GoogleMap/googleMap.scss';
@import 'components/GenerateLinkForm/generateLinkForm.scss';
@import 'components/SimpleTable/simpleTable.scss';
@import 'components/StatCounter/statCounter.scss';
@import 'components/ShareableLinks/shareableLinks.scss';
@import 'components/StatTopTable/statTopTable.scss';
@import 'components/ShareStatsIcons/shareStatsIcons.scss';
@import 'components/UsersList/usersList.scss';
@import 'components/CreateEditUser/createEditUser.scss';
@import 'components/ApplicantModal/applicantModal.scss';
@import 'components/PublicCompanyHeader/publicCompanyHeader.scss';
@import 'components/PublicCompanyFooter/publicCompanyFooter.scss';
@import 'components/ReactHint/reactHint.scss';
@import 'components/CropModal/cropModal.scss';

.redux-toastr .toastr.rrt-success {
    background-color: $blue;
    .rrt-progressbar {
        background-color: darken($blue, 10);
    }
}
