.confirmation-modal {
    max-width: 600px;
    &__title {
        background: $blue;
        color: white;
        padding: 20px 40px;
    }

    &__footer {
        padding: 20px 10px;
        .button {
            margin-left: 10px;
        }
    }

    &__content {
        padding: 30px;
        max-height: 80vh;
        overflow: auto;
        .title--regular {
            margin-top: 10px;
            font-size: 18px;
        }
    }
    .ReactModal__close {
        color: white;
    }
}