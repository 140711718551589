.referrer-worth {
    line-height: 30px;
    font-size: 18px;
    @include mobileOnly() {
        font-size: 16px;
        line-height: 25px;
    }

    &__title {
        font-weight: 500;
    }
}