$MARKER_FONT_SIZE: 40px;
.google-map {
    direction: ltr;
    &__marker {
        font-size: $MARKER_FONT_SIZE;
        text-shadow: 2px 2px rgba(black, 0.3);
        color: $blue;
        position: absolute;
        top: -$MARKER_FONT_SIZE;
        left: 0;
    }
}