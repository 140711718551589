.flex {
    display: flex;
    flex-flow: column;
    flex: 0 1 auto;
    &--inline {
        display: inline-flex;
    }
    &--horizontal {
        flex-flow: row wrap;
    }

    &--stretch {
        @include flex();
    }

    &--center {
        align-self: center;
    }
    &--noWrap {
        flex-wrap: nowrap;
    }
    &--centerChildren {
        justify-content: center;
        align-items: center;
    }
    &--justify-end {
        justify-content: flex-end;
    }
    &--justify-start {
        justify-content: flex-start;
    }
    &--justify-space-between {
        justify-content: space-between;
    }
    &--self-stretch {
        align-self: stretch;
    }
    &--mobile-col {
        @include mobileOnly {
            flex-flow: column nowrap;
        }
    }
}