.title {
    font-weight: 500;
    font-size: 20px;

    &--main {
        font-size: 30px;
        color: $blue;
        display: block;
        @include mobileOnly {
            font-size: 26px;
        }
    }
    &--spaced {
        line-height: 1.7;
    }
    &--regular {
        font-weight: 400;
    }

    &--stretch {
        @include flex();
    }

    &--center {
        align-self: center;
        text-align: center;
    }
}