.Select {
    width: 100%;

    & .Select-control {
        border: none;
        background: none;
        height: 100%;
    }
    &.has-value.Select--single.Select--rtl > .Select-control .Select-value {
        padding-right: 10px;
        padding-left: 20px;
    }
    &.has-value.Select--single > .Select-control .Select-value {
        display: flex;
        align-items: center;
    }
    &.has-value.is-clearable.Select--single.Select--rtl > .Select-control .Select-value {
        padding-right: 10px;
        padding-left: 42px;
    }
}