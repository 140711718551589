.referrer-share-modal {
    max-width: 360px;
    width: 90%;
    
    &__title {
        margin-bottom: 25px;
    }
    padding: 50px;
    
    .generate-link {
        .generate-link__submit {
            margin-top: 20px;
            top: 0;
        }
        .generate-link__row {
            flex: 0 1 100%;
        }
    }
}