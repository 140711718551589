@import 'styles/_colors.scss';
@import 'styles/_consts.scss';
@import 'styles/_reset.scss';
@import 'styles/_mixins.scss';
@import url('https://fonts.googleapis.com/css?family=Heebo:400,500,700,900&subset=hebrew');
@import url('https://use.fontawesome.com/releases/v5.0.6/css/all.css');
@import 'loaders.css/src/animations/ball-clip-rotate-multiple.scss';
@import 'loaders.css/src/animations/ball-beat.scss';
@import 'loaders.css/src/animations/ball-clip-rotate.scss';
@import '../node_modules/react-toggle/style.css';
@import '../node_modules/react-redux-toastr/lib/css/react-redux-toastr.min.css';
@import '../node_modules/react-select/dist/react-select.css';
@import '../node_modules/react-table/react-table.css';
@import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
@import '../node_modules/react-dates/lib/css/_datepicker.css';
@import '../node_modules/react-hint/css/index.css';
@import '../node_modules/cropperjs/dist/cropper.min.css';

body {
    background-color: $background;
    & * {
        font-family: 'Heebo', sans-serif;
    }
    color: $textColor;
}

* {
    color: inherit;
}

.mobile-only {
    @include webOnly {
        display: none!important;
    }
}
.web-only {
    @include mobileOnly {
        display: none!important;
    }
}