.job-highlight {
    color: $blue;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 10px;

    &:not(:last-of-type) {
        margin-right: 20px;
        @include rtl {
            margin-right: 0;
            margin-left: 20px;
        }
    }

    &__text {
        color: black;
    }

    &__label {
        display: inline-block;
    }

    &__icon {
        text-align: center;
        color: inherit;
        margin-right: 8px;
        @include rtl {
            margin-right: 0;
            margin-left: 8px;
        }
    }

    &--listed {
        flex: 1 1 calc(50% - 1px);
        padding: 50px;
        border: 0.5px solid $background;
    }

    &--sub {
        .job-highlight__text {
            color: $deepGray;
            font-size: 15px;
        }
        .job-highlight__icon {
            font-size: 12px;
            width: unset;
        }
    }
}