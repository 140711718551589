.error-msg {
    background: $errorBG;
    padding: 10px;
    display: flex;
    align-items: center;
    width: 100%;

    .fas {
        margin-right: 10px;
        @include rtl {
            margin-right: 0;
            margin-left: 10px;
        }
    }

    &.big {
        font-size: 18px;
        .fas {
            font-size: 20px;
        }
    }
}