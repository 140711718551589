.users-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    @include flex();
    position: relative;

    .ReactTable {
        .rt-td {
            height: 35px;
            align-items: center;
            display: flex;
            justify-content: center;
        }
    }
    .mfa-icon {
        color: $primary-color;
    }
}