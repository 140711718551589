.elevated-box {
    background-color:  #ffffff;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    position: relative;
    align-self: stretch;
    
    &__content {
        padding: 30px;
        align-self: stretch;
        height: 100%;
        @include flex();
        .admin-page & {
            flex: 1;
        }
        min-height: 0;
    }
    &--noPadding {
        .elevated-box__content {
            padding: 0;
        }
    }
    &__bottomLine {
        @include gradientBG();
        height: 5px;
    }
}