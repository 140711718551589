.TemplateBuilder {
    min-width: 250px;
    .input__text {
        border: 1px solid $borderGray;
    }

    &__actions {
        margin: 3px 0 4px;
    }

    &__action {
        border: 2px solid $blue;
        padding: 5px 10px;
        color: $blue;
        &:hover {
            opacity: 0.6;
        }
        &:not(:last-of-type) {
            margin-right: 5px;
            @include rtl {
                margin-right: 0;
                margin-left: 5px;
            }
        }
    }
}