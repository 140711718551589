.admin-company-jobs {
    &__preview-link {
        align-self: flex-start;
    }

    &__export {
        align-self: flex-end;
    }
    .jobs-filter {
        margin-top: 25px;
    }
}