.importCsv {
    padding: 15px 30px;
    position: relative;
    &__toggle {
        justify-content: space-between;
        font-weight: 500;
        padding: 10px 0;
        font-size: 18px;

        .subtitle {
            font-weight: 400;
            font-size: 14px;
            margin: 0 10px;
            &.success {
                color: $primary-color;
            }
            &.failure {
                color: $errorColor;
            }
        }
    }

    .file-upload {
        width: 310px;
    }

    .warning {
        color: $errorColor;
    }

    .csvTable {
        overflow-y: visible;
        margin-top: 20px;
        &.ignoreFirstLine {
            .rt-tbody .rt-tr-group:first-of-type {
                opacity: 0.3;
            }
        }
        .rt-thead .rt-resizable-header-content,
        .rt-thead .rt-resizable-header:last-child {
            overflow: visible;
        }
        .rt-table {
            overflow-x: auto;
            overflow: unset;
        }
        .rt-resizable-header-content {
            width: 100%;
            .input-wrapper {
                min-width: initial;
                width: 100%;
            }
        }
    }

    .advancedOptions {
        margin-top: 20px;
        &__toggle {
            margin-bottom: 10px;
            font-weight: 500;
            align-items: center;
            i {
                margin-left: 5px;
                @include rtl {
                    margin-left: 0;
                    margin-right: 5px;
                }
                font-size: 12px;
            }
        }
    }

    &__submit {
        align-self: flex-start;
        margin-top: 30px;
    }
}