.input-wrapper {
    display: inline-flex;
    flex-direction: column;
    min-width: $inputWidth;

    @include mobileOnly {
        min-width: 100%;
    }

    &.active {
        .label > .label_text {
            color: $blue;
        }
    }

    &.required {
        .label_text {
            position: relative;
            left: 10px;
            @include rtl {
                left: 0;
                right: 10px;
            }
            &:before {
                content: "*";
                position: absolute;
                left: -10px;
                top: 4px;
                color: red;
                text-align: start;
                @include rtl {
                    left: 0;
                    right: -10px;
                }
            }
        }
    }

    &__content {
        @include flex();
        display: flex;
        align-self: stretch;
        &--column {
            flex-direction: column;
        }
        &--ltr {
            direction: ltr;
        }
        &--noStretch {
            flex: initial;
        }
    }

    &.has-error {
        .text__indication {
            color: $errorColor;
        }
        .input__text {
            border-color: $errorColor;
        }
    }

    &.small {
        max-width: 180px;
        min-width: unset;
        @include mobileOnly {
            max-width: 100%;
        }
    }

    &.stretch {
        align-self: stretch;
    }
    
    &.flex {
        @include flex();
    }
    
    &.adaptive {
        @include flex();
        min-width: auto;
        max-width: auto;
    }

    &.has-icon {
        .input {
            padding-left: 36px;
            @include rtl {
                padding-left: 0;
                padding-right: 36px;
            }
        }

        .input-wrapper__content {
            position: relative;
            &:before {
                position: absolute;
                display: inline-block;
                left: 8px;
                top: 50%;
                @include rtl {
                    left: 0;
                    right: 8px;
                }
            }
        }
    }

    .label {
        display: flex;
        flex-direction: column;
        @include flex();
        align-self: stretch;
        height: 100%;
        > .label_text {
            margin-bottom: 5px;
            color: $deepGray;
            font-size: 15px;
            transition: color 0.5s;
            height: 15px;
        }
    }

    &:not(&--noborder) {
        .input-wrapper__content {
            border: 1px solid $borderGray;
        }
        &.has-error {
            .input-wrapper__content {
                border-color: $errorColor;
            }
        }
    }

    .Select-value {
        font-size: 18px;
    }
    .input {
        padding: 7.5px 11px;
        font-size: 18px;
        border: none;
        @include size(100%, 100%);

        &__textarea {
            min-height: 36px;
        }
        &__date {
            cursor: pointer;
            line-height: 20px;
        }
    }
    .text__indication {
        margin-top: 5px;
        min-height: 16px;
        color: green;
    }
}
