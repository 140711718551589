.Toggle {
    display: inline-flex;
    align-items: center;
    &__labelRight {
        margin-right: 10px;
        @include rtl {
            margin-right: 0;
            margin-left: 10px;
        }
    }
    &__labelLeft {
        margin-left: 10px;
        @include rtl {
            margin-left: 0;
            margin-right: 10px;
        }
    }
    .react-toggle {
        .react-toggle-track {
            background: $pink !important;
        }
        .react-toggle-thumb {
            border-color: $pink;
        }
        &--checked {
            .react-toggle-track {
                background: $blue !important;
            }
            .react-toggle-thumb {
                border-color: $blue;
            }
        }
    }

    &.twoSided {
        .react-toggle {
            .react-toggle-track {
                background: white !important;
                border: 1px solid $blue;
            }
            .react-toggle-thumb {
                border-color: white;
                background: $blue;
            }
        } 
    }
}