.create-edit-job {
    position: relative;
    @include flex();
    &__map {
        @include flex();
        height: $mapHeight;
        margin-bottom: 20px;
        width: 100%;
    }
    &__submit {
        margin: 20px 0 30px;
        align-self: flex-end;
        
        .warning {
            color: $errorColor;
            margin: 0 10px;
            font-weight: bold;
        }
    }
    &__active-toggle {
        margin: 0 20px;

        @include mobileOnly {
            order: 1;
            margin: 20px;
        }
    }
    &__top-row {
        margin-bottom: 10px;
        align-items: center;

        @include mobileOnly {
            justify-content: center;
            order: -1;
        }
    }
    .input-wrapper {
        margin-bottom: 15px;
    }
    .cover-wrapper {
        flex: 2;
    }

    &__cpyTrigger {
        margin-bottom: 15px;
        margin-right: 20px;
        @include rtl {
            margin-right: 0;
            margin-left: 20px;
        }
    }
}