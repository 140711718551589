.generate-link {
    align-items: center;
    position: relative;
    @include ieOnly() {
        width: 100%;
    }
    &__title {
        font-weight: 500;
        margin-bottom: 20px;
        font-size: 20px;
        text-align: center;
        @include ieOnly() {
            width: 100%;
        }
    }

    &__submit, .input-wrapper {
        margin: 0 5px;
    }
    &__submit {
        top: -12px;
        position: relative;

        @include mobileOnly {
            top: 0;
            margin-top: 20px;
        }
    }
    .checkbox {
        align-self: flex-start;
        max-width: 100%;
    }
}