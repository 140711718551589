.notif-settings {
    position: relative;
    @include flex();

    .error-msg {
        margin-top: 10px;
    }
    .toggle-row {
        margin-bottom: 25px;
    }
}