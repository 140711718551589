.enable-notif-modal {
    .ReactModal {
        &__Overlay {
            top: -80px;
            width: 90%;
            max-width: 425px;
            &--after-open {
                opacity: 1;
                top: 20px;
            }
            &--before-close {
                top: -150px;
            }
        }
        &__Content {
            box-shadow: 0 0 10px 4px rgba(black, 0.4);
            padding: 20px;
            font-size: 18px;
            width: 100%;
        }
    }
    .company-logo {
        margin-left: 10px;
    }
    .enm-content {
        font-size: 19px;
    }
    .enm-footer {
        margin-top: 10px;
        button {
            @include mobileOnly {
                min-width: initial;
            }
            &:not(:last-of-type) {
                margin: 0 10px;
            }
        }
    }
}