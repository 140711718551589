@import 'components/Form/DatePicker/DatePicker.scss';
@import 'components/Form/Editor/editor.scss';
@import 'components/Form/FileUpload/fileUpload.scss';
@import 'components/Form/ImageUpload/ImageUpload.scss';
@import 'components/Form/InputWrapper/InputWrapper.scss';
@import 'components/Form/Select/Select.scss';
@import 'components/Form/Toggle/toggle.scss';
@import 'components/Form/TemplateBuilder/templateBuilder.scss';
@import 'components/Form/Checkbox/checkbox.scss';
@import 'components/Form/PlacesAutoComplete/placesAutoComplete.scss';

.form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    
    &--row {
        flex-direction: row;
        button[type=submit]:before {
            content: '';
            width: 100%;
        }

    }

    &--noWrap {
        flex-wrap: nowrap;
    }

    &__section-title {
        font-size: 25px;
        font-weight: bold;
        margin: 30px 0;
    }
    .title {
        font-size: 25px;
        font-weight: bold;
        margin: 30px 0;
        display: inline-flex;
        flex-direction: column;
        .subtitle {
            font-size: 18px;
            font-weight: 400;
            margin: 4px 0 0;
            color: $deepGray;
        }
    }
    &__row {
        display: flex;
        flex-direction: row;
        align-self: stretch;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        max-width: 100%;
        &--reverse {
            flex-direction: row-reverse;
        }
        &--flex {
            @include flex();
        }
        &--justifyStart {
            justify-content: flex-start;
        }
    }
    
    &__column {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        &--flex {
            @include flex();
        }
    }

    &--margin-children {
        .input-wrapper, button[type=submit] {
            margin-right: 20px;
            @include rtl {
                margin-right: 0;
                margin-left: 20px;
            }
        }
    }
}

input, textarea {
    &:disabled, &[readonly] {
        background-color: $disabled;
    }
}