.create-company {
    width: 100%;
    margin: auto;
    padding-bottom: 25px;
    position: relative;
    
    .lang-wrapper {
        margin-bottom: 20px;
    }
    .file-upload {
        height: 220px;
        @include mobileOnly {
            min-width: 100%;
        }
    }

    &__submit {
        align-self: flex-end;

        .warning {
            color: $errorColor;
            margin: 0 10px;
            font-weight: bold;
        }
    }
    .input-wrapper {
        margin-bottom: 15px;
    }

    &__company-id-wrapper {
        position: relative;
        @include mobileOnly {
            min-width: 100%;
        }
        .loader {
            position: absolute;
            display: inline-block;
            top: 25px;
            width: 30px;
            left: -30px;
            @include rtl {
                left: unset;
                right: -30px;
            }
        }
    }

    & &__logo {
        margin-right: 20px;
        @include rtl {
            margin-right: 0;
            margin-left: 20px;
        }
        @include mobileOnly {
            width: 100%;
        }
    }
    .cover-wrapper {
        flex: 2;
    }
    @include mobileOnly {
        .form__row {
            flex-direction: column;
        }
    }
}