.cmp-route {
    @include publicWidth();
    padding-top: 0;
    min-height: calc(100vh - #{$footerHeight} - #{$publicHeaderHeight});
    .company-logo {
        align-self: center;
        margin-top: 10px;
    }
    .referrer-worth {
        margin: 10px;
        @include mobileOnly() {
            margin-top: 20px;
        }
    }
    .referrer-form-links {
        margin: 10px 0 25px;
    }
}